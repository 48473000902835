import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import { Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { getProducts } from "api/products";

import {
  ProductAttributesDialog,
  ProductTypeFilter,
  StatusLabel,
} from "components/Custom";
import { Checkbox, Dropdown, InputContainer } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { Class, QrCode2, SnippetFolder } from "icons";

import themes from "themes";

import { initialAPIListValue, ProductStatus } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";

const cells = [
  {
    id: "sku",
    label: "product_sku",
    exportLabel: "Invenyory Id",
    sortable: true,
    render: (r, exported) =>
      exported ? (
        r.sku
      ) : (
        <span
          style={{
            color: themes.default.palette.colors.blue_button,
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {r.sku}
        </span>
      ),
  },
  { id: "collection", sortable: true },
  { id: "short_size", sortable: true },
  { id: "product_type_display", label: "product_type" },
  {
    id: "status",
    render: (r, exported) =>
      exported ? r.status : <StatusLabel status={r.status} />,
  },
];

export default function Product(props) {
  const { t } = useTranslation();
  const { is_manager, related_product } = parseJwt(getWithExpiry("access"));
  const layoutDispatch = useLayoutDispatch();
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [products, setProducts] = useState(initialAPIListValue);
  const [search, setSearch] = useState({
    product_type: related_product || 1,
    status: "",
    sku: "",
    collection: "",
    seller_id: "",
  });
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [exportDisabled, setExportDisabled] = useState(false);

  const { permissions } = useUserState();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [attributesDialogOpen, setAttributesDialogOpen] = useState(false);
  const [headerCounts, setHeaderCounts] = useState({});

  useEffect(() => {
    if (products.isFetching && (is_manager || search.product_type)) {
      let params = {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        ...search,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getProducts({
        params,
        setError,
        setFunc: (res) => {
          setProducts(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [
    rowsPerPage,
    page,
    search,
    products.isFetching,
    is_manager,
    orderBy,
    orderDirection,
  ]);

  useEffect(() => {
    if (error) {
      popupNotification({
        dispatch: layoutDispatch,
        message: error,
      });
      setError(false);
    }
  }, [error, layoutDispatch]);

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setProducts({ ...products, isFetching: true });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setProducts({ ...products, isFetching: true });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setProducts({ ...products, isFetching: true });
  };

  const handleChangeFilterInput = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const handleChangeCheckbox = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.checked ? 2 : null });
    debouncedSearchHandler();
  };

  const handleProductClick = (product) => {
    if (is_manager) {
      setSelectedProduct(product);
      setAttributesDialogOpen(true);
    }
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Products-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, cells),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    if (products.items.length === products.count) {
      exportData(products.items);
    } else {
      getProducts({
        params: {
          limit: products.count,
          ...search,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setProducts((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  return (
    <>
      <PageTitle
        title={t("products")}
        button={
          <Button
            component={Link}
            to="/app/product-image"
            variant="contained"
            size="medium"
            color="secondary"
          >
            FIND IMAGE
          </Button>
        }
      />
      <InputContainer>
        <Grid container>
          {attributesDialogOpen && (
            <ProductAttributesDialog
              product={selectedProduct}
              open={attributesDialogOpen}
              onClose={() => setAttributesDialogOpen(false)}
            />
          )}
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="sku"
              label={t("product_sku")}
              variant="outlined"
              value={search.sku}
              onChange={handleChangeFilterInput("sku")}
              isProductSKU={true}
              InputProps={clearAdornment("sku")}
              LeftIcon={QrCode2}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="collection"
              label={t("collection")}
              variant="outlined"
              value={search.collection}
              onChange={handleChangeFilterInput("collection")}
              InputProps={clearAdornment("collection")}
              LeftIcon={SnippetFolder}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Dropdown
              id="status"
              title={t("status")}
              value={search.status}
              onChange={handleChangeFilterInput("status")}
              items={ProductStatus.map((status) => ({
                value: status,
                label: status,
              }))}
              LeftIcon={Class}
            />
          </Grid>
          <ProductTypeFilter
            value={search.product_type}
            setValue={(value) => setSearch({ ...search, product_type: value })}
            onChange={handleChangeFilterInput("product_type")}
          />
          <Grid item xs={12} sm={3} md={2} lg={1}>
            <Checkbox
              label="OBT"
              checked={search.seller_id}
              onChange={handleChangeCheckbox("seller_id")}
              popup="OBT Items"
            />
          </Grid>
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            headers={cells}
            headerCounts={headerCounts}
            data={products}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleExportData={handleExportData}
            rowOnClick={handleProductClick}
            disableExportData={
              !permissions.includes("product.export_data.product")
            }
            sortActions={{ onSort, orderBy, orderDirection }}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
            filters={search}
          />
        </Grid>
      </Grid>
    </>
  );
}
