import { useEffect, useState } from "react";
import { sumBy } from "lodash";

import {
  cancelAssignedLocationByLocationID,
  createInspectLocation,
} from "api/locations";
import { getInventories } from "api/inventory";
import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { ArrowBack, ExternalLink } from "icons";
import themes from "themes";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { INSPECTION_REASONS } from "utils/constants";
import { SelectBox } from "components/FormElements";
import { toTitleCase } from "utils/string";

const linkColor = themes.default.palette.colors.blue700;

export default function MarkLocationAsInspected({
  location_id,
  location_code,
  assigned_location_id,
  product_sku = null,
  afterConfirm,
  title = "LOC:",
  dialogTitle = "Problem?",
  isOpen = false,
  itemNotAvailable = false,
  onClose,
}) {
  const layoutDispatch = useLayoutDispatch();
  const [itemCount, setItemCount] = useState(0);
  const [productItemCount, setProductItemCount] = useState(0);
  const [inventories, setInventories] = useState([]);
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (location_id) {
      getInventories({
        params: {
          location_id,
          limit: 9999,
        },
        responseSetter: (res) => {
          const { results } = res;
          if (results.length) {
            setInventories(results);
            setItemCount(sumBy(results, "qty"));
            if (product_sku) {
              const product = results.find(
                (r) => r.product.sku === product_sku,
              );
              setProductItemCount(product ? product.qty : 0);
            }
          }
        },
      });
    }
  }, [location_id, product_sku]);

  const handleConfirm = (reason, formData) => {
    createInspectLocation({
      body: {
        location_id: location_id,
        reason,
        ...formData,
        is_location_based: true,
      },
      responseSetter: (res) => {
        const { success, message } = res;
        if (!success) {
          setOpen(false);
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }

        if (assigned_location_id) {
          cancelAssignedLocationByLocationID({
            id: assigned_location_id,
            body: {
              is_cancelled: true,
            },
          });
        }

        if (afterConfirm) {
          afterConfirm();
        }
        setOpen(false);
        return popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Typography
        variant="h6"
        onClick={() => setOpen(true)}
        style={{
          color: linkColor,
          textDecoration: "underline",
          verticalAlign: "middle",
          display: "inline-flex",
          cursor: "pointer",
        }}
      >
        {title} {location_code}{" "}
        {itemCount
          ? `(${productItemCount ? productItemCount + "/" : ""}${itemCount})`
          : "(0)"}
        <ExternalLink color={linkColor} size={0.9} />
      </Typography>
      <ConfirmModal
        open={open}
        title={dialogTitle}
        inventories={inventories}
        onClose={handleClose}
        onConfirm={handleConfirm}
        itemNotAvailable={itemNotAvailable}
      />
    </>
  );
}

const initialFormData = {
  qty: 1,
  product_id: "",
  note: "",
};

function ConfirmModal({
  open,
  title,
  onClose,
  onConfirm,
  inventories,
  itemNotAvailable,
}) {
  const [reason, setReason] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (!open) {
      setReason("");
    }
    if (inventories.length === 1) {
      setFormData((prev) => ({ ...prev, product: inventories[0].product?.id }));
    }
  }, [open, inventories]);

  const handleConfirmClose = () => {
    onClose();
    handleReturnBack();
  };

  const handleChangeData = (prop) => ({ target: { value } }) => {
    setFormData((prev) => ({ ...prev, [prop]: value }));
  };

  const handleReturnBack = () => {
    setReason("");
    setSelectedReason("");
  };

  if (itemNotAvailable) {
    return (
      <Dialog open={open} onClose={handleConfirmClose}>
        <DialogTitle>Item is not available.</DialogTitle>
        <DialogActions>
          <Button variant="contained" onClick={handleConfirmClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={handleConfirmClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {!selectedReason ? (
          <FormControl component="fieldset">
            <FormLabel component="legend"> Request Inspection? 🤔</FormLabel>
            <RadioGroup
              aria-label="reason"
              name="reason"
              value={reason}
              onChange={({ target: { value } }) => setReason(value)}
            >
              {Object.entries(INSPECTION_REASONS).map(([value, label]) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio />}
                  label={label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        ) : (
          <>
            <Grid
              container
              alignItems="center"
              spacing={1}
              style={{ marginTop: "-1.5rem" }}
            >
              <Grid item lg={1} style={{ marginLeft: "-1rem" }}>
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={handleReturnBack}
                >
                  <ArrowBack />
                </IconButton>
              </Grid>
              <Grid item lg={11}>
                <Typography variant="subtitle1">
                  {toTitleCase(selectedReason)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SelectBox
                  fullWidth
                  all_option_active={false}
                  title="Related Product"
                  value={formData.product_id}
                  onChange={handleChangeData("product_id")}
                  items={inventories.map((i) => ({
                    value: i.product.id,
                    label: i.product.sku,
                  }))}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="number"
                  label="Qty"
                  value={formData.qty}
                  onChange={handleChangeData("qty")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Note"
                  value={formData.note}
                  onChange={handleChangeData("note")}
                />
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {selectedReason || reason === "Empty_Location" ? (
          <>
            <Button variant="contained" onClick={handleConfirmClose}>
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onConfirm(reason, formData)}
            >
              Yes
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={!reason}
            onClick={() => setSelectedReason(reason)}
          >
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
