import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { debounce } from "lodash";
import { Box, Grid } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import { getShipments } from "api/shipment";

import { InternalLink } from "components/Custom/Links";
import { ProductTypeFilter, StatusLabel } from "components/Custom";
import {
  Button,
  DatePickerGroup,
  Dropdown,
  InputContainer,
} from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { useUserState } from "context/UserContext";

import {
  Class,
  FolderSpecial,
  Printer,
  SnippetFolder,
  Style,
  TextSnippet,
} from "icons";

import {
  initialAPIListValue,
  ShipmentServiceTypes,
  ShipmentStatus,
} from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { timezoneFormat } from "utils/date";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";
import { toTitleCase } from "utils/string";

import {
  ActionsButton,
  DeleteShipmentDialog,
  RequestCancellationDialog,
  RevertShipmentDialog,
  ShipmentCancelModal,
  ShipTheShipmentDialog,
} from "./ShipmentActions";
import ShipmentImport from "./ShipmentImport";

const initialCells = [
  {
    id: "shipment_number",
    render: (r, exported) =>
      exported ? (
        r.shipment_number
      ) : (
        <InternalLink
          to={`/app/shipments/${r.id}`}
          children={r.shipment_number}
        />
      ),
    style: {
      minWidth: "150px",
    },
    sortable: true,
  },
  {
    id: "customer",
    render: (r) => r.order.customer_id,
  },
  {
    id: "po_number",
    render: (r) => r.order.po_number,
    style: {
      minWidth: "150px",
    },
  },
  {
    id: "ship_via",
    render: (r) => toTitleCase(r.ship_via),
    style: {
      minWidth: "120px",
    },
  },
  {
    id: "status",
    render: (r, exported) =>
      exported ? (
        toTitleCase(r.status)
      ) : (
        <StatusLabel status={toTitleCase(r.status)} />
      ),
  },
  {
    id: "created_date",
    label: "created_at",
    render: (r) => timezoneFormat(r.created_date),
    style: {
      minWidth: "150px",
    },
    sortable: true,
  },
];

export default function Shipments() {
  const [t] = useTranslation();
  const history = useHistory();
  const claims = parseJwt(getWithExpiry("access"));
  const { related_product } = claims;
  const { permissions } = useUserState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [search, setSearch] = useState({
    is_small_carrier: true, // default
    status: "",
    service_type: "",
    shipment_number: "",
    ship_via: "",
    order__po_number: "",
    order__customer_id: "",
    product_type: related_product || 1,
    start_date: "",
    end_date: "",
  });
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [shipments, setShipments] = useState(initialAPIListValue);
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [exportDisabled, setExportDisabled] = useState(false);
  const [cancelRequestedShipmentID, setCancelRequestedShipmentID] = useState(
    false,
  );
  const [cancelShipmentID, setCancelShipmentID] = useState(false);
  const [cancellationOption, setCancellationOption] = useState("");
  const [revertShipmentID, setRevertShipmentID] = useState("");
  const [deleteShipmentNumber, setDeleteShipmentNumber] = useState("");
  const [shipShipmentNumber, setShipShipmentNumber] = useState("");
  const [cells, setCells] = useState(initialCells);
  const [headerCounts, setHeaderCounts] = useState({});
  const [openImport, setOpenImport] = useState(false);

  useEffect(() => {
    if (shipments.isFetching) {
      let params = {
        ...search,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getShipments({
        params,
        setFunc: (res) => {
          setShipments(res);
          setHeaderCounts(res.customCounts);
          setSelectedShipments([]);
        },
        paginated: true,
      });
    }
  }, [
    search,
    page,
    rowsPerPage,
    shipments.isFetching,
    orderDirection,
    orderBy,
  ]);

  useEffect(() => {
    const hasCancelPermission = permissions.includes(
      "contenttypes.shipment.cancel",
    );
    const hasShipShipment = permissions.includes("contenttypes.shipment.ship");
    if (hasCancelPermission || hasShipShipment) {
      setCells([
        ...initialCells,
        {
          id: "actions",
          export: false,
          render: (r) => (
            <ActionsButton
              status={r.status}
              onCancelRequestClick={(option) => {
                setCancelRequestedShipmentID(r.id);
                setCancellationOption(option);
              }}
              onCancelClick={() => setCancelShipmentID(r.id)}
              onRevertClick={() => {
                setRevertShipmentID(r.id);
              }}
              onShipClick={() => {
                setShipShipmentNumber(r.shipment_number);
              }}
              onDeleteClick={() => setDeleteShipmentNumber(r.shipment_number)}
            />
          ),
        },
      ]);
    } else {
      setCells(initialCells);
    }
  }, [permissions]);

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setShipments({ ...shipments, isFetching: true });
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    setShipments((prev) => ({ ...prev, isFetching: true }));
  };

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    setRowsPerPage(parseInt(value, 10));
    debouncedSearchHandler();
  };

  const handleChangeFilterSelect = (prop) => ({ target: { value } }) => {
    setSearch((prev) => ({ ...prev, [prop]: value }));
    debouncedSearchHandler();
  };

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    setSearch((prev) => ({ ...prev, [prop]: value }));
    debouncedSearchHandler();
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Shipments-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, cells),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    if (shipments.items.length === shipments.count) {
      exportData(shipments.items);
    } else {
      getShipments({
        params: {
          ...search,
          limit: shipments.count,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setShipments({ ...shipments, isFetching: true });
    }, 500),
    [],
  );

  return (
    <>
      <PageTitle title={t("shipments")} />
      <ShipmentImport
        open={openImport}
        close={(status) => {
          setOpenImport(false);
          if (status) {
            setShipments((prev) => ({ ...prev, isFetching: true }));
          }
        }}
      />
      <ShipmentCancelModal
        shipment_id={cancelShipmentID}
        close={() => setCancelShipmentID(false)}
        afterCancelled={() => setShipments({ ...shipments, isFetching: true })}
      />
      <RequestCancellationDialog
        shipment_id={cancelRequestedShipmentID}
        close={() => setCancelRequestedShipmentID(false)}
        afterCancelled={() => setShipments({ ...shipments, isFetching: true })}
        option={cancellationOption}
      />
      <RevertShipmentDialog
        shipment_id={revertShipmentID}
        close={(status = false) => {
          if (status) {
            setShipments({ ...shipments, isFetching: true });
          }
          setRevertShipmentID("");
        }}
      />
      <DeleteShipmentDialog
        shipment_number={deleteShipmentNumber}
        close={(status = false) => {
          if (status) {
            setShipments({ ...shipments, isFetching: true });
          }
          setDeleteShipmentNumber("");
        }}
      />
      <ShipTheShipmentDialog
        shipment_number={shipShipmentNumber}
        close={(status = false) => {
          if (status) {
            setShipments({ ...shipments, isFetching: true });
          }
          setShipShipmentNumber("");
          setSelectedShipments([]);
        }}
      />
      <InputContainer>
        <Grid container>
          <DatePickerGroup
            startDateValue={search.start_date}
            startDateOnChange={handleChangeFilterSelect("start_date")}
            endDateValue={search.end_date}
            endDateOnChange={handleChangeFilterSelect("end_date")}
            itemGridProps={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}
          />
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              id="shipment_number"
              label={t("shipment_number")}
              variant="outlined"
              fullWidth
              value={search.shipment_number}
              onChange={handleChangeFilterInput("shipment_number")}
              InputProps={clearAdornment("shipment_number")}
              LeftIcon={SnippetFolder}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              id="customer"
              label={t("customer")}
              variant="outlined"
              fullWidth
              value={search.order__customer_id}
              onChange={handleChangeFilterInput("order__customer_id")}
              InputProps={clearAdornment("order__customer_id")}
              LeftIcon={FolderSpecial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              id="po_number"
              label="PO #"
              variant="outlined"
              fullWidth
              value={search.order__po_number}
              onChange={handleChangeFilterInput("order__po_number")}
              InputProps={clearAdornment("order__po_number")}
              LeftIcon={FolderSpecial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              id="ship_via"
              label={t("ship_via")}
              variant="outlined"
              fullWidth
              value={search.ship_via}
              onChange={handleChangeFilterInput("ship_via")}
              InputProps={clearAdornment("ship_via")}
              LeftIcon={TextSnippet}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Dropdown
              id="service_type"
              title={t("service_type")}
              value={search.service_type}
              onChange={handleChangeFilterSelect("service_type")}
              items={ShipmentServiceTypes}
              LeftIcon={Style}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Dropdown
              id="status"
              title={t("status")}
              value={search.status}
              onChange={handleChangeFilterSelect("status")}
              items={ShipmentStatus.map((item) => ({
                value: item,
                label: item,
              }))}
              LeftIcon={Class}
            />
          </Grid>
          <ProductTypeFilter
            value={search.product_type}
            setValue={(product_type) => setSearch({ ...search, product_type })}
            onChange={handleChangeFilterSelect("product_type")}
          />
          <Box
            component={Grid}
            item
            lg={
              permissions.includes("contenttypes.shipper.download_labels")
                ? 0
                : 3
            }
            display={{
              xs: "none",
              sm: "none",
              md: "none",
              lg: "block",
            }}
            style={{ borderRight: "none" }}
          />
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Button color="danger" onClick={() => setOpenImport(true)}>
              Import Shipments
            </Button>
          </Grid>
          {permissions.includes("contenttypes.shipper.download_labels") && (
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Button
                variant="contained"
                size="medium"
                color="secondary"
                onClick={() => {
                  history.push("/app/shipments-to-print");
                }}
              >
                <Printer color="white" />{" "}
                <span style={{ marginLeft: 5 }}>Shipments To Print</span>
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Button
              color="primary"
              disabled={!selectedShipments.length}
              onClick={() => {
                setShipShipmentNumber(
                  selectedShipments.map((shipment) => shipment.shipment_number),
                );
              }}
            >
              Ship
            </Button>
          </Grid>
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            data={shipments}
            headers={cells}
            headerCounts={headerCounts}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleExportData={handleExportData}
            sortActions={{ onSort, orderBy, orderDirection }}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
            filters={search}
            hasCheckBox={search.status === "Picked"}
            selected={selectedShipments}
            setSelected={setSelectedShipments}
          />
        </Grid>
      </Grid>
    </>
  );
}
