import {
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { getProductLabels } from "api/locations";
import { TextInputWithKeyboard } from "components/FormElements";
import PageTitle from "components/PageTitle";
import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { Qrcode, QrCode2 } from "icons";
import React, { useRef, useState } from "react";

const SIZES = ["1x2", "1x3", "2x3", "2x4"];

export default function ProductLabel() {
  const qtyRef = useRef(null);
  const skuRef = useRef(null);
  const layoutDispatch = useLayoutDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    sku: "",
    qty: 1,
    label_size: localStorage.getItem("label_size") || SIZES[SIZES.length - 1],
  });

  const handleSubmit = () => {
    if (!formData.qty) {
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Qty cannot be null!",
      });
    } else if (Number(formData.qty) > 50) {
      setFormData((prev) => ({ ...prev, qty: 50 }));
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Quantity cannot exceed 50",
      });
    }

    setIsLoading(true);

    getProductLabels({
      body: {
        sku: formData.sku,
        qty: Number(formData.qty),
        label_size: formData.label_size,
      },
      responseSetter: ({ success, base64, message }) => {
        if (!success) {
          setIsLoading(false);
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }
        printPDF(base64);
      },
    });
  };

  const printPDF = async (base64PDF) => {
    try {
      const base64Data = base64PDF.replace(
        /^data:application\/pdf;base64,/,
        "",
      );
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);

      // Görünmez iframe oluştur
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);

      iframe.src = blobUrl;

      // iframe yüklendikten sonra yazdırma işlemini başlat
      iframe.onload = () => {
        try {
          iframe.contentWindow.print();
        } catch (error) {
          console.error("Yazdırma işlemi başlatılamadı:", error);
        } finally {
          setIsLoading(false);
        }
      };
    } catch (error) {
      console.error("PDF yazdırma hatası:", error);
    }
  };

  return (
    <>
      <PageTitle title="Custom Product Label" />
      <Grid container spacing={2} style={{ paddingTop: "20px" }}>
        <Grid item xs={12}>
          <FormControl component="fieldset" variant="outlined">
            <FormLabel component="legend"> Label Size:</FormLabel>
            <RadioGroup
              aria-label="label_size"
              name="label_size"
              title="Label Size"
              value={formData.label_size}
              onChange={({ target: { value } }) => {
                setFormData((prev) => ({ ...prev, label_size: value }));
                localStorage.setItem("label_size", value);
              }}
              row
            >
              {SIZES.map((value) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio />}
                  label={value}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextInputWithKeyboard
            fullWidth
            autoFocus
            inputRef={skuRef}
            size="medium"
            name="sku"
            label="SKU"
            variant="outlined"
            value={formData.sku}
            onChange={({ target: { value } }) => {
              setFormData((prev) => ({ ...prev, sku: value }));
            }}
            onKeyPress={({ key }) => {
              if (key === "Enter") {
                qtyRef?.current.focus();
              }
            }}
            LeftIcon={Qrcode}
            hasBorder
          />
        </Grid>
        <Grid item xs={12}>
          <TextInputWithKeyboard
            fullWidth
            inputRef={qtyRef}
            type="number"
            size="medium"
            name="quantity"
            inputMode="numeric"
            label="Quantity"
            variant="outlined"
            error={
              Number.isNaN(parseInt(formData.qty)) ||
              parseInt(formData.qty) < 0 ||
              parseInt(formData.qty) > 999
            }
            value={formData.qty}
            onChange={({ target: { value } }) => {
              setFormData((prev) => ({ ...prev, qty: value }));
            }}
            onKeyPress={({ key }) => {
              if (key === "Enter") {
                handleSubmit();
              }
            }}
            LeftIcon={QrCode2}
            hasBorder
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!formData.sku || isLoading}
            onClick={handleSubmit}
          >
            Print
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
