import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import * as XLSX from "xlsx";
import DefaultTable from "components/Table";
import { initialAPIListValue } from "utils/constants";
import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { CloudUpload } from "icons";
import useStyles from "pages/containers/styles";
import { importEcommerceShipment } from "api/shipment";

export default function ShipmentImport({ open, close }) {
  const classes = useStyles();
  const layoutDispatch = useLayoutDispatch();
  const [shipments, setShipments] = useState(initialAPIListValue);
  const [isFetching, setIsFetching] = useState(false);

  const headers = [
    {
      id: "order_number",
      label: "Order/ PO/ Shipment Number",
      render: (r) => (
        <>
          <span title={r["Order Number"]}>{r["Order Number"]}</span>
          <Typography variant="subtitle2" className={classes.subtitle}>
            <span title="PO Number">{r["PO Number"]}</span> /{" "}
            <span title="Shipment Number">{r["Shipment Number"]}</span>
          </Typography>
        </>
      ),
    },
    {
      id: "marketplace",
      label: "Marketplace / Ship Via",
      render: (r) => (
        <>
          <span title="Marketplace">{r["Marketplace"]}</span>
          <Typography variant="subtitle2" className={classes.subtitle}>
            <span title="Ship Via">{r["Ship Via"]}</span>
          </Typography>
        </>
      ),
    },
    {
      id: "sku",
      render: (row) => row["SKU"],
    },
    {
      id: "qty",
      render: (row) => row["Quantity"],
    },
    {
      id: "customer_name",
      label: "Customer",
      render: (r) => (
        <>
          <span title={r["Customer Name"]}>{r["Customer Name"]}</span>
          <Typography variant="subtitle2" className={classes.subtitle}>
            <span title="Phone">{r["Phone"]}</span>
          </Typography>
        </>
      ),
    },
    {
      id: "line_1",
      label: "Line 1",
      render: (row) => row["Line 1"],
    },
    {
      id: "line_2",
      label: "Line 2",
      render: (row) => row["Line 2"],
    },
    {
      id: "state",
      label: "Other",
      render: (r) => (
        <>
          <span title={r["City"]}>{r["City"]}</span>
          <Typography variant="subtitle2" className={classes.subtitle}>
            <span title="State">{r["State"]}</span> /{" "}
            <span title="Country">{r["Country"]}</span> /{" "}
            <span title="Zip Code">{r["Zip Code"]}</span>
          </Typography>
        </>
      ),
    },
  ];

  const handleClose = (status) => {
    close(status);
    setShipments(initialAPIListValue);
    setIsFetching(false);
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const f = files[0];
    const reader = new FileReader();

    reader.onload = function (e) {
      const data = e.target.result;
      const readedData = XLSX.read(data, {
        type: "binary",
        cellDates: true,
        dateNF: "yyyy-mm-dd",
      });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      const values = XLSX.utils
        .sheet_to_json(ws, { raw: false })
        .filter((v) => v["Order Number"] && v["Customer Name"] && v["SKU"]);

      if (!values.length) {
        popupNotification({
          dispatch: layoutDispatch,
          message:
            "Please add Order Number, Customer Name, SKU columns in your excel file",
          status: "error",
        });
        return;
      }

      if (values.length > 100) {
        popupNotification({
          dispatch: layoutDispatch,
          message: "Shipment count cannot be more than 100.",
          status: "error",
        });
        return;
      }

      setShipments({
        count: values.length,
        items: values,
      });
    };
    reader.readAsBinaryString(f);
    e.target.value = "";
  };

  const handleSubmit = async () => {
    const data = { shipments: [] };
    const groupedShipments = {};

    shipments.items.forEach((item) => {
      const key = `${item["Order Number"]}_${item["PO Number"]}_${item["Shipment Number"]}`;

      if (!groupedShipments[key]) {
        groupedShipments[key] = {
          order_number: item["Order Number"],
          po_number: item["PO Number"],
          shipment_number: item["Shipment Number"],
          ship_via: item["Ship Via"],
          marketplace: item["Marketplace"],
          address: {
            customer: item["Customer Name"],
            line_1: item["Line 1"],
            line_2: item["Line 2"] || "",
            city: item["City"],
            state: item["State"],
            zip_code: item["Zip Code"],
            phone: item["Phone"],
            country: item["Country"] || "US",
          },
          details: [],
        };
      }

      groupedShipments[key].details.push({
        sku: item["SKU"],
        quantity: parseInt(item["Quantity"]) || 0,
      });
    });

    data.shipments = Object.values(groupedShipments);
    setIsFetching(true);

    importEcommerceShipment({
      data,
      responseSetter: ({ status, errors }) => {
        setIsFetching(false);

        if (status === "error" && errors) {
          const errorMessages = errors.map((error) => {
            const shipmentNumber = error.shipment_data.shipment_number;
            if (typeof error.errors === "string") {
              return error.errors;
            } else {
              const errorDetails = Object.entries(error.errors)
                .map(([key, messages]) => `${key}: ${messages.join(", ")}`)
                .join("\n");
              return `Shipment ${shipmentNumber}:\n${errorDetails}`;
            }
          });
          popupNotification({
            dispatch: layoutDispatch,
            message: errorMessages.join("\n"),
          });
        } else {
          popupNotification({
            dispatch: layoutDispatch,
            message: "Shipment(s) created successfully",
            status: "success",
          });
          handleClose(true);
        }
      },
    });
  };

  return (
    <Dialog onClose={() => handleClose()} open={open} maxWidth="md" fullWidth>
      <DialogTitle>Import Shipment</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <label htmlFor="file_picker">
              <input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: "none" }}
                id="file_picker"
                type="file"
                onChange={handleFileChange}
              />
              <Button
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                component="span"
                disabled={isFetching}
              >
                <span style={{ paddingRight: "2px" }}>
                  <CloudUpload color="#fff" size={0.8} />
                </span>
                Upload
              </Button>
            </label>
          </Grid>

          {isFetching ? (
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          ) : shipments.count ? (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <DefaultTable headers={headers} data={shipments} />
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="danger"
          onClick={() => handleClose()}
          disabled={isFetching}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isFetching || !shipments.count}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
