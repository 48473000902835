import { useState } from "react";

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import * as XLSX from "xlsx";

import { getLabels } from "api/locations";

import { Button, InputContainer } from "components/Form";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";
import Widget from "components/Widget";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { CloudUpload, Download } from "icons";

import { downloadPDF } from "utils/base64";
import { initialAPIListValue } from "utils/constants";
import { exportToExcel } from "utils/exportToExcel";
import { toTitleCase } from "utils/string";
import {
  locationCodeValidation,
  productAttributeValidation,
} from "utils/validations";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const EXAMPLE_LOCATION_CODE = "123-123-12";
const EXAMPLE_PRODUCT_SKU = "ABC1234 1x2";

const LabelTypes = [
  { value: "location", label: "Location" },
  { value: "product", label: "Product" },
];
const headers = [{ id: "value" }];

export default function LabelMaker() {
  const history = useHistory();
  const layoutDispatch = useLayoutDispatch();
  const [data, setData] = useState(initialAPIListValue);
  const [labelType, setLabelType] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const validation = (row) => {
    if (!labelType) {
      return false;
    }

    if (labelType === "location") {
      return !locationCodeValidation(row.value);
    }

    return !productAttributeValidation(row.value);
  };

  const handleFileChange = (e) => {
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, {
        type: "binary",
        cellDates: true,
        dateNF: "yyyy-mm-dd",
      });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const values = XLSX.utils
        .sheet_to_json(ws, {
          raw: false,
        })
        .map((r) => r.values)
        .filter(
          (r) => r && ![EXAMPLE_PRODUCT_SKU, EXAMPLE_LOCATION_CODE].includes(r),
        );

      if (!values.length) {
        popupNotification({
          dispatch: layoutDispatch,
          message: "Values not found",
        });
        return;
      }

      if (values.length > 100) {
        popupNotification({
          dispatch: layoutDispatch,
          message: "Values count cannot be more than 100.",
        });
        return;
      }

      setData({
        count: values.length,
        items: values.map((v) => ({ id: `${v}_${Math.random()}`, value: v })),
        isFetching: false,
      });
    };
    reader.readAsBinaryString(f);
    e.target.value = "";
  };

  const handleSubmit = () => {
    if (!labelType) {
      popupNotification({
        dispatch: layoutDispatch,
        message: "Please select a label type!",
        status: "warning",
      });
      return;
    }
    const values = data.items.map((code) => code.value);
    setIsFetching(true);
    getLabels({
      body: {
        values,
        label_type: labelType,
      },
      responseSetter: (res) => {
        const { base64, message, success } = res;
        if (!success) {
          popupNotification({
            dispatch: layoutDispatch,
            message,
          });
          setIsFetching(false);
          return;
        }
        const today = new Date();
        const title = `${toTitleCase(
          labelType,
        )}-Labels-${today.toLocaleDateString()}`;
        downloadPDF(base64, title);
        setIsFetching(false);
      },
    });
  };

  return (
    <>
      <PageTitle title="Label Maker" />
      <InputContainer>
        <Grid container justifyContent="space-between">
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={labelType}
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  padding: "6px",
                }}
                onChange={({ target: { value } }) => {
                  setLabelType(value);
                }}
              >
                {LabelTypes.map((lt) => (
                  <FormControlLabel
                    key={lt.value}
                    value={lt.value}
                    control={<Radio color="primary" />}
                    label={lt.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  onClick={() => history.push("/app/product-label")}
                >
                  Custom Label
                </Button>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    exportToExcel(
                      [
                        {
                          values:
                            labelType === "location"
                              ? EXAMPLE_LOCATION_CODE
                              : EXAMPLE_PRODUCT_SKU,
                        },
                      ],
                      "label_maker_template",
                    )
                  }
                >
                  <span style={{ paddingRight: "2px" }}>
                    <Download color="#fff" size={0.8} />
                  </span>
                  Download Template
                </Button>
              </Grid>
              <Grid item sm={4} xs={12}>
                <label htmlFor="file_picker">
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{ display: "none" }}
                    id="file_picker"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    component="span"
                    disabled={isFetching}
                  >
                    <span style={{ paddingRight: "2px" }}>
                      <CloudUpload color="#fff" size={0.8} />
                    </span>
                    Upload File
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        {!data.items.length ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Alert severity="info"> Please upload file </Alert>
          </Grid>
        ) : (
          <>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Widget>
                <DefaultTable
                  headers={headers}
                  data={data}
                  highlightFunc={validation}
                />
              </Widget>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Widget>
                <Button
                  color={"primary"}
                  fullWidth
                  onClick={handleSubmit}
                  disabled={isFetching}
                >
                  Download Labels
                </Button>
              </Widget>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
