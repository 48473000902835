import React, { useState, useEffect, useRef } from "react";

import _ from "lodash";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { getLocations } from "api/locations";
import { getProductsWithAttributes } from "api/products";
import { buggyShip, ecommOrderShip, getShipmentDetails } from "api/shipment";

import { MobileButtonContainer, MobileResetButton } from "components/Custom";
import { TextInputWithKeyboard } from "components/FormElements";
import PageTitle from "components/PageTitle";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import {
  LocalShipping,
  QrCode2,
  SettingsRemote,
  Looks,
  ShoppingCart,
} from "icons";

import { formatTrackingNumber, isExpress } from "utils/carrier";
import { timezoneFormat } from "utils/date";
import { clearInput } from "utils/dom";
import { toTitleCase } from "utils/string";

import useStyles from "./styles";
import { zipCodes } from "utils/zipCodes";

const initialBuggyForm = {
  buggyCode: "",
  truckCode: "",
  buggyId: "",
};
const allow_buggy_ship = process.env.REACT_APP_ALLOW_BUGGY_SHIP || false;

export default function EcommShip() {
  const classes = useStyles();
  const [t] = useTranslation();
  const trackingNumberEl = useRef(null);
  const productRef = useRef(null);
  const emptyDivRef = useRef(null);
  const layoutDispatch = useLayoutDispatch();
  const [shipments, setShipments] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState({});
  const [selectedCarrier, setSelectedCarrier] = useState("FEDEX");
  const [shipViaClass, setShipViaClass] = useState("");
  const [shippedShipments, setShippedShipments] = useState([]);
  const [isBuggy, setIsBuggy] = useState(false);
  const [buggyForm, setBuggyForm] = useState(initialBuggyForm);
  const [keyboardState, setKeyboardState] = useState("none");
  let isFetching = false;

  const [showTrailer, setShowTrailer] = useState("");

  useEffect(() => {
    getShipmentDetails({
      params: {
        status: "Picked",
        shipment__is_small_carrier: true,
        limit: 1000,
      },
      responseSetter: ({ results }) => {
        setShipments(results);
      },
    });
  }, []);

  useEffect(() => {
    if (selectedCarrier) {
      setShipViaClass(
        selectedCarrier === "FEDEX" ? classes.fedex : classes.ups,
      );
      setSelectedShipment({});
    }
  }, [selectedCarrier, classes]);

  useEffect(() => {
    function getKeyboardState(event) {
      if (event.key === "keyboardState") {
        setKeyboardState(event.newValue);
      }
    }
    window.addEventListener("storage", getKeyboardState);
    return () => {
      window.removeEventListener("storage", getKeyboardState);
    };
  }, []);

  useEffect(() => {
    if (keyboardState !== "none") {
      window.scrollBy(0, 400);
    } else {
      window.scrollBy(0, -300);
    }
  }, [keyboardState]);

  const getShipmentByTrackingNumber = (tracking_number) => {
    getShipmentDetails({
      params: {
        tracking_number: tracking_number,
        shipment__ship_via: selectedCarrier,
      },
      responseSetter: (data) => {
        const { results } = data;
        if (!results.length) {
          clearInput(trackingNumberEl);
          popupNotification({
            dispatch: layoutDispatch,
            message: t("shipment_not_found"),
          });
          setSelectedShipment({});
        } else if (results.length === 1) {
          const error = shipmentValidation(results[0]);
          if (error) {
            clearInput(trackingNumberEl);
            return popupNotification({
              dispatch: layoutDispatch,
              message: error,
            });
          }
          setSelectedShipment(results[0]);
        } else {
          const shipment = results.find((res) => res.status === "Picked");
          if (shipment) {
            return setSelectedShipment(shipment);
          }
          const resultStatuses = _.uniqBy(results, "status");
          if (
            resultStatuses.length === 1 &&
            ["Shipped", "Invoiced"].includes(resultStatuses[0].status)
          ) {
            clearInput(trackingNumberEl);
            return popupNotification({
              dispatch: layoutDispatch,
              message: t("already_shipped"),
            });
          }
          clearInput(trackingNumberEl);
          popupNotification({
            dispatch: layoutDispatch,
            message: t("shipment_not_found"),
          });
          setSelectedShipment({});
        }
      },
    });
  };

  const getProduct = (productValue) => {
    if (isFetching) return;
    const productSKULower = productValue.toLowerCase();
    const shipmentProductSKULower = selectedShipment.product.sku.toLowerCase();
    isFetching = true;
    if (
      productSKULower === shipmentProductSKULower ||
      productSKULower.split(",")[0] === shipmentProductSKULower
    ) {
      return handleShip(selectedShipment);
    }
    const availableLabel = shipments.filter(
      (shipment) =>
        shipment.product.sku.toLowerCase() === productSKULower &&
        shipment.tracking_number === selectedShipment.tracking_number,
    );
    if (availableLabel.length) {
      return handleShip(availableLabel[0]);
    }
    getProductsWithAttributes({
      params: {
        value_iexact: productValue,
      },
      responseSetter: (data) => {
        const { results } = data;
        if (!results.length) {
          isFetching = false;
          popupNotification({
            dispatch: layoutDispatch,
            message: t("product_not_found"),
            afterCloseFunction: () => clearInput(productRef),
          });
        } else if (results.length === 1) {
          const error = productValidation(results[0]);
          if (error) {
            isFetching = false;
            clearInput(productRef);
            return popupNotification({
              dispatch: layoutDispatch,
              message: error,
              afterCloseFunction: () => clearInput(productRef),
            });
          }
          handleShip(selectedShipment);
        } else {
          const product_skus = _.uniqBy(results, "product_sku").map(
            (x) => x.product_sku,
          );
          if (product_skus.length === 1) {
            const error = productValidation(results[0]);
            if (error) {
              isFetching = false;
              return popupNotification({
                dispatch: layoutDispatch,
                message: error,
                afterCloseFunction: () => clearInput(productRef),
              });
            }
            handleShip(selectedShipment);
          } else {
            isFetching = false;
            popupNotification({
              dispatch: layoutDispatch,
              message: "Multiple Products Found",
              afterCloseFunction: () => clearInput(productRef),
            });
          }
        }
      },
    });
  };

  const handleProductSearch = ({ key, target }) => {
    if (key === "Enter") {
      getProduct(target.value);
    }
  };

  const handleShipmentSearch = ({ key, target }) => {
    let trackingvalue = target.value;
    if (key === "Enter" && trackingvalue.length > 11) {
      trackingvalue = formatTrackingNumber(trackingvalue);
      if (
        (selectedCarrier === "FEDEX" && trackingvalue.startsWith("1Z")) ||
        (selectedCarrier === "UPS" && !trackingvalue.startsWith("1Z"))
      ) {
        clearInput(trackingNumberEl);
        return popupNotification({
          dispatch: layoutDispatch,
          message: t("carrier_and_tracking_number_do_not_match"),
        });
      }
      if (trackingNumberEl.current) {
        trackingNumberEl.current.value = trackingvalue;
      }
      const totalInShipped = shippedShipments.filter(
        (shipment) => shipment.tracking_number === trackingvalue,
      );
      const totalInShipments = shipments.filter(
        (shipment) => shipment.tracking_number === trackingvalue,
      );

      if (totalInShipped.length && !totalInShipments.length) {
        clearInput(trackingNumberEl);
        return popupNotification({
          dispatch: layoutDispatch,
          message: t("already_shipped"),
        });
      }

      const findShipment = shipments.find(
        (shipment) => shipment.tracking_number === trackingvalue,
      );
      if (findShipment) {
        const error = shipmentValidation(findShipment);
        if (error) {
          clearInput(trackingNumberEl);
          return popupNotification({
            dispatch: layoutDispatch,
            message: error,
          });
        }

        if (selectedCarrier === "FEDEX") {
          const zip_code = findShipment?.shipment?.zip_code;
          if (zipCodes.has(zip_code)) {
            console.log("A Trailer");
            setShowTrailer("A");
          } else {
            setShowTrailer("B");
          }
        }
        setSelectedShipment(findShipment);
      } else {
        getShipmentByTrackingNumber(trackingvalue);
      }
    }
  };

  const productValidation = (product) => {
    if (product.product !== selectedShipment.product.id) {
      return t("wrong_product");
    }
  };

  const shipmentValidation = (shipmentResult) => {
    if (
      shipmentResult.status === "Shipped" ||
      shipmentResult.status === "Invoiced"
    ) {
      let shipTransaction = shipmentResult.transactions.find(
        (t) => t.action_type === "Shipped",
      );
      const date = new Date(timezoneFormat(shipTransaction.transaction_time));
      const time = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return `${t("the_shipment_is_shipped_by")} ${
        shipTransaction.user.username
      } ${t("at")} ${time}`;
    } else if (shipmentResult.status !== "Picked") {
      if (
        shipmentResult.status === "Cancelled" ||
        shipmentResult.status === "Pending_Cancellation"
      ) {
        return t("canceled_shipment_pick_message");
      }
      return t("the_shipment_should_be_picked_first");
    }
  };

  const handleShip = (label) => {
    ecommOrderShip({
      body: {
        tracking_number: label.tracking_number,
        detail_id: label.id,
        product_id: label.product.id,
      },
      responseSetter: (data) => {
        isFetching = false;
        const { success, message } = data;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: message,
          });
        }
        setShowTrailer("");
        clearInput(trackingNumberEl);
        popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
        if (isExpress(label.shipment.ship_via)) {
          setTimeout(
            () =>
              popupNotification({
                dispatch: layoutDispatch,
                message: "EXPRESS",
                status: "success",
              }),
            10,
          );
        }
        setShippedShipments([{ ...shippedShipments }, label]);
        const removed = _.remove(shipments, (shipment) => {
          return (
            shipment.tracking_number === label.tracking_number &&
            shipment.id === label.id &&
            shipment.product.id === label.product.id
          );
        });
        let shouldAdd = [];
        if (removed.length > 1) {
          shouldAdd = removed.slice(1);
        }
        setShipments(_.concat(shipments, shouldAdd));
        setSelectedShipment({});
        if (trackingNumberEl.current) {
          trackingNumberEl.current.focus();
        }
        return;
      },
      setError: (error) => {
        setShowTrailer("");
        isFetching = false;
        popupNotification({
          dispatch: layoutDispatch,
          message: error,
        });
      },
    });
  };

  const handleBuggyCodeSubmit = ({ key }) => {
    if (key === "Enter") {
      const code = toTitleCase(buggyForm.buggyCode).replace("-", " ");
      getLocations({
        params: {
          code,
          location_type: 4, // Buggy
        },
        responseSetter: (data) => {
          if (!data.results.length) {
            return popupNotification({
              dispatch: layoutDispatch,
              message: `${t("invalid")} ${t("location")}`,
            });
          } else if (data.results.length === 1) {
            setBuggyForm({ ...buggyForm, buggyId: data.results[0].id });
          } else {
            const filteredResults = data.results.filter((x) => x.code === code);
            if (filteredResults.length) {
              setBuggyForm({ ...buggyForm, buggyId: filteredResults[0].id });
            } else {
              popupNotification({
                dispatch: layoutDispatch,
                message: `${t("invalid")} ${t("location")}`,
              });
            }
          }
        },
      });
    }
  };

  const submitBuggyForm = () => {
    if (!buggyForm.buggyId || !buggyForm.truckCode) {
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Buggy and Truck barcodes are required",
      });
    }
    buggyShip({
      body: {
        buggy_id: buggyForm.buggyId,
        carrier: buggyForm.truckCode,
      },
      responseSetter: (data) => {
        const { success, message } = data;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: message,
          });
        }
        setBuggyForm(initialBuggyForm);
        setShowTrailer("");
        return popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
      },
      setError: (error) => {
        popupNotification({
          dispatch: layoutDispatch,
          message: error,
        });
      },
    });
  };

  const handleClear = () => {
    clearInput(trackingNumberEl);
    clearInput(productRef);
    setShowTrailer("");
    setSelectedShipment({});
    if (trackingNumberEl.current) {
      trackingNumberEl.current.focus();
    }
  };

  return (
    <>
      <PageTitle
        title={`${t("ship").toUpperCase()}`}
        helpText={`${
          _.uniq(shipments.map((s) => s.tracking_number)).length
        } remaining`}
      />
      {allow_buggy_ship && (
        <div style={{ display: "block", minHeight: "7rem" }}>
          <Grid
            container
            alignItems="center"
            spacing={4}
            justifyContent="flex-start"
            style={{ paddingTop: "20px" }}
          >
            <Grid item onClick={() => setIsBuggy(true)}>
              <ShoppingCart color={"#000000"} />
              <Typography variant="caption" component={"h3"}>
                Buggy
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={!isBuggy}
                onChange={() => setIsBuggy(!isBuggy)}
                color="primary"
              />
            </Grid>
            <Grid item onClick={() => setIsBuggy(false)}>
              <Looks />
              <Typography variant="caption" component={"h3"}>
                Product
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
      {isBuggy ? (
        <Card>
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextInputWithKeyboard
                fullWidth
                variant="outlined"
                label={t("Buggy")}
                value={buggyForm.buggyCode}
                onChange={({ target: { value } }) =>
                  setBuggyForm({ ...buggyForm, buggyCode: value })
                }
                autoFocus={true}
                onKeyPress={handleBuggyCodeSubmit}
                onBlur={() => handleBuggyCodeSubmit({ key: "Enter" })}
                LeftIcon={SettingsRemote}
              />
            </Grid>
            {buggyForm.buggyId && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextInputWithKeyboard
                  fullWidth
                  variant="outlined"
                  label={t("truck")}
                  value={buggyForm.truckCode}
                  onChange={({ target: { value } }) =>
                    setBuggyForm({ ...buggyForm, truckCode: value })
                  }
                  autoFocus
                  onKeyPress={({ key }) => {
                    if (key === "Enter") submitBuggyForm();
                  }}
                  LeftIcon={LocalShipping}
                />
              </Grid>
            )}
            {buggyForm.truckCode && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <MobileButtonContainer>
                  <Button
                    style={{ margin: "10px", marginTop: "0px" }}
                    variant="contained"
                    color="primary"
                    onClick={submitBuggyForm}
                  >
                    {t("Ship")}
                  </Button>
                </MobileButtonContainer>
              </Grid>
            )}
          </Grid>
        </Card>
      ) : (
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid
              container
              spacing={4}
              className={shipViaClass}
              justifyContent="space-evenly"
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl component="fieldset" style={{ width: "100%" }}>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={selectedCarrier}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onChange={({ target: { value } }) => {
                      setSelectedCarrier(value);
                      setShowTrailer("");
                      if (trackingNumberEl.current) {
                        trackingNumberEl.current.focus();
                      }
                    }}
                  >
                    <Button>
                      <FormControlLabel
                        value="FEDEX"
                        style={{ color: "#000", fontWeight: "bolder" }}
                        control={<Radio color="primary" />}
                        label="FEDEX"
                      />
                    </Button>
                    <Button>
                      <FormControlLabel
                        value="UPS"
                        style={{ color: "#000", fontWeight: "bolder" }}
                        control={<Radio color="primary" />}
                        label="UPS"
                      />
                    </Button>
                  </RadioGroup>
                </FormControl>
              </Grid>
              {selectedCarrier && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextInputWithKeyboard
                    fullWidth
                    variant="outlined"
                    dark_mode={false}
                    inputRef={trackingNumberEl}
                    label={t("tracking_number")}
                    autoFocus={true}
                    onKeyPress={handleShipmentSearch}
                    LeftIcon={LocalShipping}
                  />
                </Grid>
              )}
              {Object.keys(selectedShipment).length ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextInputWithKeyboard
                    fullWidth
                    autoFocus
                    variant="outlined"
                    dark_mode={false}
                    label={t("product")}
                    inputRef={productRef}
                    placeholder={selectedShipment.product.sku}
                    onKeyPress={handleProductSearch}
                    LeftIcon={QrCode2}
                    showKeyboard={false}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid container>
        <Grid item xs={12}>
          <div style={{ minHeight: "400px", width: "100%" }} ref={emptyDivRef}>
            {showTrailer ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    backgroundColor:
                      showTrailer === "A" ? "#0072ff" : "#ff7e00",
                    color: "white",
                    fontSize: "18px",
                    borderRadius: 6,
                    width: "150px",
                    height: "150px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "40px",
                  }}
                >
                  <span style={{ fontSize: "70px" }}>{showTrailer}</span>

                  <Typography variant="body">Trailer</Typography>
                </Box>
              </Box>
            ) : null}
          </div>
        </Grid>
      </Grid>

      <MobileResetButton
        onClick={() =>
          isBuggy ? setBuggyForm(initialBuggyForm) : handleClear()
        }
      />
    </>
  );
}
