const CUSTOMERS = [
  "At Home",
  "Bargain Hunt",
  "BI Mart",
  "Biglots",
  "Bluestem",
  "Bostwick",
  "Burlington",
  "Family Dollars",
  "Hobby Lobby",
  "Marshall",
  "Menards",
  "OSJ",
  "OTP",
  "Ross",
  "Tayse",
  "TJXX",
  "Trees n Trend",
];

const DESTINATIONS = [
  "Calhoun",
  "Door Move",
  "Port Pickup",
  "FOB",
  "Wayfair CG",
];

const IMPORTERS = ["Tayse", "Artistic USA"];

const LINES = [
  "Arkas",
  "CMA",
  "Evergreen",
  "Hapag",
  "Maersk",
  "MSC",
  "One Line",
  "TURKON",
  "Wan Hai",
  "Zim",
  "OOCL",
];

const PO_TYPES = [
  {
    value: "Ecommerce",
    label: "Ecom",
  },
  {
    value: "National",
    label: "Nat",
  },
  {
    value: "Furniture",
    label: "Furn",
  },
  {
    value: "Mix",
    label: "Mix",
  },
];

const RECEIPT_TYPES = ["Ecom", "Nat", "Mix", "Furn"];

const SHIP_TO_PORTS = [
  "Ambarlı",
  "Baltimore",
  "Catlai",
  "Charleston",
  "Chicago",
  "CHIPPEWA FALLS",
  "DETROIT",
  "İskenderun",
  "OMAHA",
  "Long Beach",
  "Los Angeles",
  "Mersin",
  "New York",
  "NHAVA SHEVA",
  "Ningbo",
  "Norfork",
  "San Juan",
  "Savannah",
  "Seattle",
  "Tianjin",
  "Xingang",
];

const VENDORS_MANUFACTURERS = [
  "AHENK CARPET",
  "ANATOLIAN",
  "ANGEL",
  "ANGEL CARPET",
  "ANGORA HALI",
  "ARTISTICCARP",
  "AYZA",
  "BAHARIYE",
  "BALAT",
  "BASARAN",
  "BEIJING HOME",
  "BEIJINGFRDM",
  "BOSSAN CARPET",
  "CANVAS RUGS",
  "CAPITALCARP",
  "CAPITOLA",
  "CAYIRLI HALI",
  "CCGRASS",
  "CONFETTI",
  "CONRAD",
  "COVTEX",
  "DECORTIE",
  "DEKORISTER",
  "DOKUPA",
  "ERYUN HALI",
  "EYMES",
  "FESTIVAL HALI",
  "GALAXY",
  "GLAMOURTEX",
  "GRAND CARPET",
  "GYL GLOBAL LIMITED",
  "HAKAN CARPET",
  "HASAN KAPLAN",
  "HASMERT",
  "HOBBY LOBBY",
  "HM",
  "HRM / DAHHAN",
  "JINANYAJIE",
  "JOUDHALI",
  "KAPLAN KARDESLER HALI",
  "KAPLANSER",
  "KASHMIR",
  "KOZA",
  "LINEA HALI",
  "MARS CARPET",
  "MATPACK",
  "MILAT CARPET",
  "MUTAS CARPET",
  "MY EVILLA",
  "NEO",
  "NINGBO OCEAN",
  "NORM HALI",
  "NURTEKS",
  "OSCAR HALI TEKSTIL",
  "OZKAPLAN",
  "OZTURKLER TEKSTIL",
  "PRESTIJCARPET",
  "RAKAM",
  "RAVANDA",
  "RAYE",
  "SAHAN CARPET",
  "SAHIL PLASTIC",
  "SAMIRA",
  "SAMUR CARPETS",
  "SEGES HALI",
  "SHANDONG YONCA CARPET",
  "SIRMA",
  "STEFANY",
  "TAYFUR SAHIN",
  "TIANJIN HAIRUN",
  "TIANJIN HAITUO",
  "TIANJIN KING HONZA CARPET",
  "UNIVCARP",
  "UNIVERSAL INC",
  "UVI",
  "VALENTIS",
  "VITAL HALI",
  "WESSE",
  "YAJIE VIETNAM COMPANY",
  "YAKAR TEKSTIL",
  "YASIN KAPLAN",
  "LYSANDRA HALI",
  "ARTISTIC USA LLC",
  "ZHEJIANG WUYI",
];

export {
  CUSTOMERS,
  DESTINATIONS,
  IMPORTERS,
  LINES,
  PO_TYPES,
  RECEIPT_TYPES,
  SHIP_TO_PORTS,
  VENDORS_MANUFACTURERS,
};
