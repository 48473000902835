import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components
import Sidebar from "components/Sidebar";
import { PopupNotification } from "components/Custom";

import { useLayoutState } from "context/LayoutContext";

// pages
import Adjustments from "pages/adjustment/Adjustments";
import CastlegatePDFGenerator from "pages/pdf-generator/Castlegate";
import CreateReturn from "pages/returns/CreateReturn";
import Containers from "pages/containers/Containers";
import CycleCount from "pages/count/CycleCount";
import CycleCountAssign from "pages/count/Assign";
import CycleCounts from "pages/count/CycleCounts";
import DuplicateItems from "pages/duplicate-items/DuplicateItems";
import EcommerceAssign from "pages/ecommerce/Assign";
import EcommercePick from "pages/ecommerce/Pick";
import EcommerceUnassign from "pages/ecommerce/Unassign";
import EcommShip from "pages/ecommerce/Ship";
import Inventory from "pages/inventory/Inventory";
import InventoryDashboard from "pages/dashboard/Inventory";
import InventorySnapShot from "pages/inventory/Snapshot";
import InventoryMobile from "pages/inventory/InventoryMobile";
import LabelMaker from "pages/label-maker/LabelMaker";
import Location from "pages/location/Location";
import LocationSKUReport from "pages/location-sku-report/LocationSKUReport";
import LocationSKUValidation from "pages/location-sku-report/LocationSKUValidation";
import LTLPackaging from "pages/ltl/Packaging";
import LTLPick from "pages/ltl/Pick";
import LTLPickList from "pages/ltl/PickList";
import LTLShipments from "pages/ltl/Shipments";
import LTLShipmentDetail from "pages/ltl/ShipmentDetail";
import ManuallyTypedSKUs from "pages/ecommerce/ManuallyTypedSKUs";
import MovementHistory from "pages/movement-history";
import MyWork from "pages/my-work/MyWork";
import NationalInventoryCounter from "pages/national-count/counter";
import NationalInventoryCountGroup from "pages/national-count/count_group";
import NationalInventoryCouuntGroupDetail from "pages/national-count/detail_count_group";
import OfficeDashboard from "pages/dashboard/Office";
import PickerPerformanceReport from "pages/ecommerce/PickerPerformance";
import PickerStatistics from "pages/ecommerce/PickerStatistics";
import Product from "pages/product/Product";
import ProductConsolidation from "pages/product-consolidation/ProductConsolidation";
import ProductImage from "pages/product/ProductImage";
import Profile from "pages/profile/Profile";
import Promotions from "pages/promotions/Promotions";
import Receipt from "pages/receipt/Receipt";
import ReceiptDetail from "pages/receipt/ReceiptDetail";
import ReceivingReport from "pages/receipt/ReceivingReport";
import ReceiptTransfer from "pages/receipt/ReceiptTransfer";
import Returns from "pages/returns/Returns";
import Shelf from "pages/shelf/Shelf";
import ShipmentDetail from "pages/shipments/ShipmentDetail";
import Shipments from "pages/shipments/Shipments";
import ShipmentsToPrint from "pages/shipments/ShipmentsToPrint";
import ShipperDashboard from "pages/dashboard/Shipper";
import ShippingDashboard from "pages/ecommerce/ShippingDashboard";
import Stock from "pages/stock/Stock";
import TransactionReport from "pages/reports/TransactionReport";
import Transfer from "pages/transfer/Transfer";
import Transfers from "pages/transfer/Transfers";
import User from "pages/user/User";
import ValidateCrossReferences from "pages/product/ValidateCrossReferences";
import ProductLabel from "pages/label-maker/ProductLabel";

function Layout(props) {
  const { t } = useTranslation();
  const online = window.navigator.onLine;
  const [internetStatus, setInternetStatus] = useState(online);
  const { isMobile } = useLayoutState();
  const classes = useStyles();

  const handleOffline = () => setInternetStatus(false);
  const handleOnline = () => setInternetStatus(true);

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div className={classes.root}>
      <>
        {!internetStatus && isMobile && (
          <div className={classes.isOffline}>
            <span className={classes.center}>
              {t("check_your_internet_connection")}
            </span>
          </div>
        )}
        <PopupNotification />
        <Sidebar />
        <div className={classes.content}>
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route
              path="/app/adjustments"
              component={Adjustments}
              exact={true}
            />
            <Route
              path="/app/castlegate-pdf-generator"
              component={CastlegatePDFGenerator}
            />

            <Route path="/app/count" component={CycleCount} exact={true} />
            <Route
              path="/app/count/assign"
              component={CycleCountAssign}
              exact={true}
            />
            <Route path="/app/cycle-counts" component={CycleCounts} />
            <Route
              path="/app/item-counts-group"
              component={NationalInventoryCountGroup}
              exact={true}
            />
            <Route
              path="/app/item-counts-group/:id"
              component={NationalInventoryCouuntGroupDetail}
            />
            <Route path="/app/containers" component={Containers} />
            <Route
              path="/app/item-count"
              component={NationalInventoryCounter}
            />
            <Route path="/app/label-maker" component={LabelMaker} />
            <Route
              path="/app/office-dashboard"
              component={OfficeDashboard}
              exact={true}
            />
            <Route path="/app/duplicate-items" component={DuplicateItems} />
            <Route
              path="/app/inventory-dashboard"
              component={InventoryDashboard}
            />
            <Route path="/app/inventory/list" component={Inventory} />
            <Route
              path="/app/inventory-snapshot"
              component={InventorySnapShot}
            />
            <Route
              path="/app/inventory/list-mobile"
              component={InventoryMobile}
            />
            <Route path="/app/locations" component={Location} />
            <Route
              path="/app/location-sku-report"
              component={LocationSKUReport}
            />
            <Route
              path="/app/location-sku-validation"
              component={LocationSKUValidation}
            />
            <Route
              path="/app/ltl/packaging"
              component={LTLPackaging}
              exact={true}
            />
            <Route
              path="/app/ltl/pick/:shipment_id"
              component={LTLPick}
              exact={true}
            />
            <Route
              path="/app/ltl/pick-list"
              component={LTLPickList}
              exact={true}
            />
            <Route
              path="/app/ltl/shipments"
              component={LTLShipments}
              exact={true}
            />
            <Route
              path="/app/ltl/shipments/:shipment_id"
              component={LTLShipmentDetail}
            />

            <Route path="/app/movement-history" component={MovementHistory} />
            <Route
              path="/app/shipping-dashboard"
              component={ShippingDashboard}
              exact={true}
            />
            <Route
              path="/app/shipper-dashboard"
              component={ShipperDashboard}
              exact={true}
            />
            <Route path="/app/stock" component={Stock} />
            <Route
              path="/app/transaction-report"
              component={TransactionReport}
              exact={true}
            />
            <Route path="/app/transfer" component={Transfer} exact={true} />
            <Route path="/app/transfers" component={Transfers} exact={true} />
            <Route path="/app/ecommerce/assign" component={EcommerceAssign} />
            <Route
              path="/app/ecommerce/unassign"
              component={EcommerceUnassign}
            />
            <Route
              path="/app/ecommerce/pick"
              component={EcommercePick}
              exact={true}
            />
            <Route
              path="/app/ecommerce/ship"
              component={EcommShip}
              exact={true}
            />
            <Route
              path="/app/ecommerce/manually-typed-skus"
              component={ManuallyTypedSKUs}
            />
            <Route
              path="/app/ecommerce/picker-statistics"
              component={PickerStatistics}
              exact={true}
            />
            <Route
              path="/app/ecommerce/picker-performance"
              component={PickerPerformanceReport}
              exact={true}
            />
            <Route
              path="/app/product-consolidations"
              component={ProductConsolidation}
            />
            <Route path="/app/products" component={Product} />
            <Route path="/app/product-image" component={ProductImage} />
            <Route
              path="/app/validate-cross-references"
              component={ValidateCrossReferences}
            />
            <Route path="/app/profile" component={Profile} />
            <Route path="/app/product-label" component={ProductLabel} />
            <Route path="/app/promotions" component={Promotions} />
            <Route path="/app/receipt" component={Receipt} exact={true} />
            <Route
              path="/app/receiving-report"
              component={ReceivingReport}
              exact={true}
            />
            <Route
              path="/app/receipt/:id"
              component={ReceiptDetail}
              exact={true}
            />
            <Route
              path="/app/receipt-transfer"
              component={ReceiptTransfer}
              exact={true}
            />
            <Route path="/app/create-return" component={CreateReturn} />
            <Route path="/app/returns" component={Returns} />
            <Route path="/app/shelf" component={Shelf} />
            <Route path="/app/shipments" component={Shipments} exact={true} />
            <Route
              path="/app/shipments-to-print"
              component={ShipmentsToPrint}
              exact={true}
            />
            <Route
              path="/app/shipments/:shipment_id"
              component={ShipmentDetail}
              exact={true}
            />
            <Route path="/app/my-work" component={MyWork} exact={true} />
            <Route path="/app/user" component={User} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
