import { API, addParamsToEndpoint } from "utils/api";

const api = new API();

const getLocations = ({
  setFunc,
  setError,
  paginated,
  params,
  responseSetter,
}) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/locations/", params),
    setError,
    setFunc,
    paginated,
    responseSetter,
  });
};

const getStageLocations = ({ responseSetter }) => {
  api.list({
    endpoint: "custom/stage-locations",
    responseSetter,
  });
};

const getAisles = ({ setFunc, setError }) => {
  api.list({
    endpoint: "warehouse/aisles/",
    setError,
    setFunc,
  });
};

const getLevels = ({ setFunc, setError }) => {
  api.list({
    endpoint: "warehouse/levels/",
    setError,
    setFunc,
  });
};

const getLabels = ({ responseSetter, body }) => {
  api.create({
    endpoint: "custom/get-labels/",
    body,
    responseSetter,
  });
};

const getProductLabels = ({ responseSetter, body }) => {
  api.create({
    endpoint: "custom/get-custom-product-labels/",
    body,
    responseSetter,
  });
};

const getLocationAssignData = ({ params, setFunc, setError }) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/location-assign/", params),
    paginated: true,
    setError,
    setFunc,
  });
};

const assignLocationsToCounters = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/assign-locations/",
    body,
    responseSetter,
  });
};

const reassignCountLocations = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/reassign-locations/",
    body,
    responseSetter,
  });
};

const unassignCountLocations = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/unassign-locations/",
    body,
    responseSetter,
  });
};

const getAssignedLocations = ({ setFunc, params }) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/assigned-locations/", params),
    paginated: true,
    setFunc,
  });
};

const assignedLocationsSummary = ({ responseSetter }) => {
  api.list({
    endpoint: "custom/assigned-locations/",
    responseSetter,
  });
};

const cancelAssignedLocationByLocationID = ({ id, body, responseSetter }) => {
  api.update({
    endpoint: `warehouse/assigned-locations/${id}/`,
    body,
    responseSetter,
  });
};

const createInspectLocation = ({ body, responseSetter }) => {
  api.create({
    endpoint: "custom/create-inspect-location/",
    body,
    responseSetter,
  });
};

const completeInspectLocation = ({ body, responseSetter }) => {
  api.update({
    endpoint: `custom/complete-inspect-location/`,
    body,
    responseSetter,
  });
};

const getInspectLocations = ({ setFunc, params }) => {
  api.list({
    endpoint: addParamsToEndpoint("warehouse/inspect-location/", params),
    paginated: true,
    setFunc,
  });
};

const updateLocation = ({ id, body, responseSetter }) => {
  api.update({
    endpoint: `warehouse/locations/${id}/`,
    body,
    responseSetter,
  });
};

export {
  assignedLocationsSummary,
  assignLocationsToCounters,
  cancelAssignedLocationByLocationID,
  completeInspectLocation,
  createInspectLocation,
  getAisles,
  getAssignedLocations,
  getLabels,
  getLevels,
  getLocationAssignData,
  getLocations,
  getInspectLocations,
  getStageLocations,
  getProductLabels,
  reassignCountLocations,
  unassignCountLocations,
  updateLocation,
};
